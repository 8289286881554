import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Header from "../components/header"
import TopHat from "../components/tophat"
import Footer from "../components/footer"
import NewsPreview from '../components/news-preview'
import Content from "../components/content"
import SEO from "../components/seo"
import logo from "../images/logo.png"
import showdown from 'showdown'
const converter = new showdown.Converter()

const ArchivePage = ({data, pageContext}) => {
    return (
        <Layout>
            <SEO title="Board of Directors" />
            <TopHat />
            <div className="main">
                <Header district={pageContext.district}  />
                <div className="main-content">
                    <div className="title-section">
                        <small style={{display: 'inline-block',paddingBottom: '1rem'}}>Hays WCID {pageContext.district}</small>
                        <h1 className="title is-size-1">News Archive</h1>
                    </div>
                    <NewsPreview district={pageContext.district} posts={data.graphAPI.blogPosts} />
                </div>
                <Footer district={pageContext.district}></Footer>
            </div>
        </Layout>
    )
}

export default ArchivePage

export const query = graphql`
    query archivepagebyID($district: ID!) {
        graphAPI {
            blogPosts(where: {districts: {id: $district}}, sort: "publishedAt:desc") {
                id
                title
                description
                publishedAt
                heroImage {
                    url
                    updated_at
                    documentFile {
                        childImageSharp {
                            fluid(maxWidth: 2048){
                                ...GatsbyImageSharpFluid_tracedSVG
                            }
                        }
                    }
                }
            }
        } 
    }
`


